import React from "react";
import about from "../assets/about.jpg";
import { motion } from "framer-motion";

const About = () => {
  return (
    <div
      className=" w-6/6 mx-auto flex items-center justify-center \"
      id="about"
    >
      <div className="flex flex-col md:flex-row md:space-x-8 max-w-5xl mx-4">
        <motion.div
          initial={{ opacity: 0, x: 40 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: 40 }}
          transition={{
            type: "tween",
            duration: 3,
            ease: [0.25, 0.25, 0.25, 0.75],
          }}
          className="flex flex-col md:flex-row-reverse justify-between items-center gap-10"
        >
          <div className="md:w-1/2">
            <img
              src={about}
              alt="About Us"
              className="w-full h-full rounded-lg shadow-lg"
            />
          </div>
          <div className="md:w-1/2 mt-8 md:mt-0">
            <h1 className="text-4xl md:text-4xl font-bold font-quicksand flex justify-end text-royal-blue mb-6">
              About Us
            </h1>
            <p className="text-xl font-bold     text-gray text-justify">
              At Knack Bay Technologies Pvt Ltd, we are dedicated to becoming
              the main automation partner for industrial clients. Our
              responsibility includes guiding them through their digital
              transformation journey and assisting them in reaching their
              business goals. Our mission is straightforward to empower
              businesses with tailor-made and creative IT solutions. We are
              continually working towards reshaping the digital landscape by
              offering cutting-edge development services.
            </p>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default About;
