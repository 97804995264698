import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Index from "./components/Index";
import Navbar from "./components/demo/Index";
import WebDevelop from "./components/ServicesPage/WebDevelop";
import UIUx from "./components/ServicesPage/UIUx";
import Appdevelop from "./components/ServicesPage/Appdevelop";
import Slider from "./components/Slider";
import "@fortawesome/fontawesome-free/css/all.css";
import { HelmetProvider } from "react-helmet-async";

function App() {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path="/" element={<Navbar />}>
            <Route path="Webpage" element={<WebDevelop />} />
            <Route path="UiUx" element={<UIUx />} />
            <Route path="App" element={<Appdevelop />} />
            <Route path="slide" element={<Slider />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
