import React from "react";
import Navbar from "./Navbar";
import Home from "./Home";
import About from "./About";
import Technology from "./Technology";
import Clients from "./Clients";
import Footer from "./Footer";
import Services from "./Services";
import OurProduct from "./OurProducts/OurProduct";
import { Helmet } from "react-helmet-async";

const Index = () => {
  return (
    <>
      <Helmet>
        <title>Home Page - Kncakbay</title>
        <meta
          name="description"
          content="Elevate your business with tailored software solutions. We deliver cutting-edge applications designed to meet your specific needs and drive growth."
        />

        {/* Open Graph meta tags */}
        <meta property="og:title" content="Home Page - Kncakbay" />
        <meta
          property="og:description"
          content="Elevate your business with tailored software solutions. We deliver cutting-edge applications designed to meet your specific needs and drive growth."
        />
        <meta
          property="og:image"
          content="https://knackbay.s3.ap-south-1.amazonaws.com/favicon.png"
        />
        <meta property="og:url" content="https://www.kncakbay.com/" />
        <meta property="og:type" content="website" />
        <link rel="canonical" href={`https://www.kncakbay.com/`} />

        {/* Schema.org JSON-LD */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebSite",
            url: "https://knackbay.com",
            name: "Kncakbay",
            description:
              "Elevate your business with tailored software solutions. We deliver cutting-edge applications designed to meet your specific needs and drive growth.",
            publisher: {
              "@type": "Organization",
              name: "Kncakbay",
              logo: "https://knackbay.s3.ap-south-1.amazonaws.com/favicon.png",
            },
          })}
        </script>
      </Helmet>
      <div className="">
        <Navbar />
        <div className="w-6/6">
          <Home />
          <About />
          <Technology />
          <Services />
          <OurProduct />
          <Clients />
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Index;
