import React from "react";
import wws from "../assets/wws.jpg";
import entro from "../assets/entoro.png";
import clowak from "../assets/clowak.png";
import xpro from "../assets/xpro4.jpg";
import onemind from "../assets/onemind.png";
import etech from "../assets/etech.jpg";

const logos = [
  { src: wws, alt: "wws" },
  { src: entro, alt: "entro" },
  { src: clowak, alt: "clowak" },
  { src: onemind, alt: "onemind" },
  { src: xpro, alt: "xpro" },
  { src: etech, alt: "etech" }
];

const Clients = () => {
  return (
    <div className="max-w-screen-2xl mt-8">
      <div className="flex flex-wrap justify-center gap-16">
        {logos.map((logo, index) => (
          <div key={index} className="flex items-center justify-center mb-5">
            <img src={logo.src} alt={logo.alt} className="h-auto max-h-48" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Clients;
