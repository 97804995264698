import React from 'react';
import banner from '../assets/cartoon1.jpg';
import { motion } from 'framer-motion';

const Home = () => {
  return (
    <div className="md:px-12 p-4 max-w-screen-2xl mx-auto mt-28 " id="home">
      <div className="rounded-xl overflow-hidden">
        <motion.div
          initial={{ opacity: 0, x: -40 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -40 }}
          transition={{ type: 'tween', duration: 3, ease: [0.25, 0.25, 0.25, 0.75] }}
          className="flex flex-col md:flex-row-reverse justify-between items-center gap-10"
        >
          <motion.div className="md:w-1/2">
            <img src={banner} alt="" className="w-full h-auto rounded-lg shadow-lg" />
          </motion.div>

          <motion.div className="md:w-1/2">
            <h2 className="text-4xl md:text-4xl font-bold font-quicksand text-royal-blue mb-4  text-center md:text-left">
              Discover the perfect software application for the unique requirements of your business
            </h2>

            <p className="text-lg text-gray">
              Elevate your business with tailored software solutions that meet your specific needs. Our team of experts is dedicated to providing cutting-edge applications designed to propel your business to new heights.
            </p>
          </motion.div>
        </motion.div>
      </div>
    </div>
  );
};

export default Home;
