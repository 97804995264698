import React from "react";
import { motion } from "framer-motion";
import uiux from "../../assets/app2.jpg";
import ui1 from "../../assets/app3.jpg";
import ui2 from "../../assets/app.jpg";
import ui3 from "../../assets/app1.jpg";
import ui4 from "../../assets/app2.jpg";
import { Link } from "react-router-dom";

const Appdevelop = () => {
  const fadeInVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  return (
    <div className="motion-container">
      <div
        className="md:px-12 p-4 max-w-screen-2xl mx-auto mt-28 bg-cover bg-center relative h-96 object-fit"
        style={{ backgroundImage: `url(${uiux})` }}
      >
        <div className="bg-black bg-opacity-60 absolute inset-0"></div>
        <div className="relative z-10 text-white flex flex-col md:flex-row justify-center items-center gap-10 md:h-full">
          <div className="md:w-[60%]"></div>
          <div className="text-center md:text-left">
            <div className="text-4xl md:text-5xl font-bold mb-4">
              Welcome to Our Web Development Services
            </div>
            <Link
              to="/"
              className="hover:text-gray-300 cursor-pointer"
            >
              <button className="bg-pink text-white px-4 py-2 rounded-full">
                Contact Us
              </button>
            </Link>
          </div>
        </div>
      </div>

      <div className="max-w-screen-2xl mx-auto w-5/6">
        <motion.div
          initial={{ opacity: 0, x: 40 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: 40 }}
          transition={{
            type: "tween",
            duration: 3,
            ease: [0.25, 0.25, 0.25, 0.75],
          }}
          className="flex flex-col md:flex-row-reverse justify-between items-center gap-10"
        >
          <div className="flex flex-col md:flex-row">
            <div className="w-full md:w-1/2 mx-auto">
              <div className="overflow-hidden transform hover:translate-y-4 transition-all duration-300 max-w-sm mx-auto flex">
                <div className="w-96 h-96 mx-auto rounded-full bg-gradient-to-r from-purple-500 to-pink-500 shadow-custom object-cover">
                  <img
                    src={ui3}
                    alt=""
                    className="h-full w-full rounded-full"
                    style={{ objectPosition: "50% 50%" }}
                  />
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/2 mx-auto mt-12 md:mt-0 text-black">
              <h1 className="text-3xl md:text-4xl font-bold mb-4 text-center md:text-left">
                Proven Excellence in Android App Development
              </h1>
              <p className="text-lg text-gray p-4 text-justify">
                A Symphony of Triumphs we don't just promise; we coordinate. Our
                expertise in Android app development resounds in a portfolio
                enriched with triumphs that resonate with success. Each triumph
                weaves into the tapestry of our proven track record, speaking
                volumes of our prowess. The threads of successful apps,
                meticulously crafted, have inscribed their mark. With a legacy
                of achievements, we harmonize experience and expertise to
                compose exceptional results that stand as enduring monuments of
                innovation.
              </p>
            </div>
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, x: -40 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -40 }}
          transition={{
            type: "tween",
            duration: 3,
            ease: [0.25, 0.25, 0.25, 0.75],
          }}
          className="flex flex-col md:flex-row justify-between items-center gap-10 mt-10"
        >
          <div className="flex flex-col md:flex-row">
            <div className="w-full md:w-1/2 mx-auto mt-12 md:mt-0 text-black">
              <h1 className="text-3xl md:text-4xl font-bold mb-4 text-center md:text-left">
                Native App Development
              </h1>
              <p className="text-lg text-gray text-justify">
                Native app development involves creating applications
                specifically for a particular platform or operating system, such
                as iOS or Android, using platform-specific programming languages
                and development tools. At [Your Company Name], we specialize in
                native app development, leveraging the unique features and
                capabilities of each platform to deliver high-performance and
                user-friendly applications tailored to our clients' needs.
              </p>
            </div>
            <div className="w-full md:w-1/2 mx-auto">
              <div className="overflow-hidden transform hover:translate-y-4 transition-all duration-300 max-w-sm mx-auto flex">
                <div className="w-96 h-96 mx-auto rounded-full bg-gradient-to-r from-purple-500 to-pink-500 shadow-custom object-cover">
                  <img
                    src={ui2}
                    alt=""
                    className="h-full w-full rounded-full"
                    style={{ objectPosition: "50% 50%" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, x: 40 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: 40 }}
          transition={{
            type: "tween",
            duration: 3,
            ease: [0.25, 0.25, 0.25, 0.75],
          }}
          className="flex flex-col md:flex-row-reverse justify-between items-center gap-10 mt-10"
        >
          <div className="flex flex-col md:flex-row">
            <div className="w-full md:w-1/2 mx-auto">
              <div className="overflow-hidden transform hover:translate-y-4 transition-all duration-300 max-w-sm mx-auto flex">
                <div className="w-96 h-96 mx-auto rounded-full bg-gradient-to-r from-purple-500 to-pink-500 shadow-custom object-cover">
                  <img
                    src={ui1}
                    alt=""
                    className="h-full w-full rounded-full"
                    style={{ objectPosition: "50% 50%" }}
                  />
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/2 mx-auto mt-12 md:mt-0 text-black">
              <h1 className="text-3xl md:text-4xl font-bold mb-4 text-center md:text-left">
                Cross-Platform App Development
              </h1>
              <p className="text-lg text-gray text-justify">
                Cross-platform app development allows the creation of
                applications that can run on multiple platforms using a single
                codebase. This approach offers cost-effectiveness and faster
                time-to-market compared to developing separate native apps for
                each platform. At [Your Company Name], we excel in
                cross-platform app development, utilizing frameworks like React
                Native and Flutter to build versatile and scalable apps that
                reach a broader audience.
              </p>
            </div>
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, x: 40 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: 40 }}
          transition={{
            type: "tween",
            duration: 3,
            ease: [0.25, 0.25, 0.25, 0.75],
          }}
          className="flex flex-col md:flex-row justify-between items-center gap-10 mt-10"
        >
          <div className="flex flex-col md:flex-row">
            <div className="w-full md:w-1/2 mx-auto mt-12 md:mt-0 text-black">
              <h1 className="text-3xl md:text-4xl font-bold mb-4 text-center md:text-left">
                App Maintenance and Support
              </h1>
              <p className="text-lg text-gray text-justify">
                App maintenance and support are essential for ensuring the
                smooth operation and performance of mobile applications
                post-launch. This includes bug fixes, updates for new OS
                versions, security patches, and ongoing technical support. At
                [Your Company Name], we provide comprehensive maintenance and
                support services to keep your apps running smoothly and adapt to
                evolving user needs and technological advancements.
              </p>
            </div>
            <div className="w-full md:w-1/2 mx-auto">
              <div className="overflow-hidden transform hover:translate-y-4 transition-all duration-300 max-w-sm mx-auto flex">
                <div className="w-96 h-96 mx-auto rounded-full bg-gradient-to-r from-purple-500 to-pink-500 shadow-custom object-cover">
                  <img
                    src={ui4}
                    alt=""
                    className="h-full w-full rounded-full"
                    style={{ objectPosition: "50% 50%" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default Appdevelop;
