import React, { useState, useEffect } from "react";
import figma from "../assets/figma.jpg";
import flutter from "../assets/flutter.jpg";
import react from "../assets/react.png";
import mongo from "../assets/mongodb.png";
import angular from "../assets/angularjs.png";
import mysql from "../assets/mysql.png";
import nodejs from "../assets/node-js.png";
import tailwind from "../assets/tailwind.jpg";

const Technology = () => {
  const slides = [
    figma,
    flutter,
    react,
    mongo,
    angular,
    mysql,
    nodejs,
    tailwind,
  ];
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleCircleClick = (index) => {
    setCurrentIndex(index);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [currentIndex]);

  return (
    <div className="container mx-auto my-8 text-center relative">
      <div>
        <h1 className="text-royal-blue font-quicksand text-4xl font-bold mb-5">
          Core Technology
        </h1>
      </div>

      <div className="flex flex-wrap justify-center gap-4 md:gap-2">
        {slides.map((image, index) => (
          <div
            key={index}
            className={`w-32 h-32 md:w-32 md:h-32  flex items-center justify-center bg-white rounded-full overflow-hidden shadow-custom hover:shadow-custom-lg transform hover:translate-y-4 transition-all duration-300 ${
              index === currentIndex ? "border-4 border-gray" : ""
            }`}
          >
            <img
              src={image}
              alt={`Technology Image ${index + 1}`}
              className="w-full h-full p-4 object-cover"
            />
          </div>
        ))}
      </div>

      <div className="flex justify-center gap-2 mt-4">
        {slides.map((s, index) => (
          <div
            onClick={() => handleCircleClick(index)}
            key={`circle-${index}`}
            className={`rounded-full w-2 h-2 md:w-3 md:h-3 ${
              index === currentIndex ? "bg-gray" : "bg-textWhite"
            } cursor-pointer transition-all duration-300 transform hover:scale-125`}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default Technology;
