import React from "react";
import { motion } from "framer-motion";
import uiux from "../../assets/uiux.png";
import ui1 from "../../assets/ui1.jpg";
import ui2 from "../../assets/ui2.jpg";
import ui3 from "../../assets/ui3.jpg";
import ui4 from "../../assets/ui4.jpg";
import { Link } from "react-router-dom";

const UIUx = () => {
  const fadeInVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  return (
    <div className="motion-container">
      <div
        className="md:px-12 p-4 max-w-screen-2xl mx-auto mt-28 bg-cover bg-center relative h-96 object-fill"
        style={{ backgroundImage: `url(${uiux})` }}
      >
        <div className="bg-black bg-opacity-60 absolute inset-0"></div>
        <div className="relative z-10 text-white flex flex-col md:flex-row justify-center items-center gap-10 md:h-full">
          <div className="md:w-[60%]"></div>
          <div className="text-center md:text-left">
            <div className="text-3xl md:text-5xl font-bold mb-4">
              Welcome to Our Web Development Services
            </div>
            <Link to="/" className="hover:text-gray-300 cursor-pointer">
              <button className="bg-pink text-white px-4 py-2 rounded-full">
                Contact Us
              </button>
            </Link>
          </div>
        </div>
      </div>

      <div className="max-w-screen-2xl mx-auto w-5/6 mt-6">
        <motion.div
          initial={{ opacity: 0, x: 40 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: 40 }}
          transition={{
            type: "tween",
            duration: 3,
            ease: [0.25, 0.25, 0.25, 0.75],
          }}
          className="flex flex-col md:flex-row-reverse justify-between items-center gap-10"
        >
          <div className="flex flex-col md:flex-row">
            <div className="w-full md:w-1/2 mx-auto">
              <div className="overflow-hidden transform hover:translate-y-4 transition-all duration-300 max-w-sm mx-auto flex">
                <div className="w-80 h-80 md:w-96 md:h-96 mx-auto rounded-full bg-gradient-to-r from-purple-500 to-pink-500 shadow-custom object-cover">
                  <img
                    src={ui3}
                    alt=""
                    className="h-full w-full rounded-full"
                    style={{ objectPosition: "50% 50%" }}
                  />
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/2 mx-auto mt-12 md:mt-0 text-black">
              <h1 className="text-2xl md:text-4xl font-bold mb-4 text-center md:text-left">
                Creative Excellence in Designing
              </h1>
              <p className="text-base md:text-lg text-gray p-4 text-justify">
                User Experience (UX) refers to the overall experience a person
                has when interacting with a product or service, especially in
                terms of how easy or pleasing it is to use. This encompasses
                aspects such as usability, accessibility, and emotional
                satisfaction. At [Your Company Name], we prioritize UX design to
                ensure that our products not only meet but exceed user
                expectations, leading to increased customer satisfaction and
                loyalty.
              </p>
            </div>
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, x: -40 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -40 }}
          transition={{
            type: "tween",
            duration: 3,
            ease: [0.25, 0.25, 0.25, 0.75],
          }}
          className="flex flex-col md:flex-row justify-between items-center gap-10 mt-10"
        >
          <div className="flex flex-col md:flex-row">
            <div className="w-full md:w-1/2 mx-auto mt-12 md:mt-0 text-black">
              <h1 className="text-2xl md:text-4xl font-bold mb-4 text-center md:text-left">
                User Interface (UI)
              </h1>
              <p className="text-base md:text-lg text-gray p-4 text-justify">
                User Interface (UI) focuses on the visual and interactive
                elements of a product or service that users interact with. This
                includes everything from buttons and menus to typography and
                color schemes. Our team at [Your Company Name] pays meticulous
                attention to UI design, crafting interfaces that are not only
                aesthetically pleasing but also intuitive and user-friendly. By
                creating visually engaging and accessible interfaces, we enhance
                the overall user experience.
              </p>
            </div>
            <div className="w-full md:w-1/2 mx-auto">
              <div className="overflow-hidden transform hover:translate-y-4 transition-all duration-300 max-w-sm mx-auto flex">
                <div className="w-80 h-80 md:w-96 md:h-96 mx-auto rounded-full bg-gradient-to-r from-purple-500 to-pink-500 shadow-custom object-cover">
                  <img
                    src={ui2}
                    alt=""
                    className="h-full w-full rounded-full"
                    style={{ objectPosition: "50% 50%" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, x: 40 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: 40 }}
          transition={{
            type: "tween",
            duration: 3,
            ease: [0.25, 0.25, 0.25, 0.75],
          }}
          className="flex flex-col md:flex-row-reverse justify-between items-center gap-10 mt-10"
        >
          <div className="flex flex-col md:flex-row">
            <div className="w-full md:w-1/2 mx-auto">
              <div className="overflow-hidden transform hover:translate-y-4 transition-all duration-300 max-w-sm mx-auto flex">
                <div className="w-80 h-80 md:w-96 md:h-96 mx-auto rounded-full bg-gradient-to-r from-purple-500 to-pink-500 shadow-custom object-cover">
                  <img
                    src={ui4}
                    alt=""
                    className="h-full w-full rounded-full"
                    style={{ objectPosition: "50% 50%" }}
                  />
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/2 mx-auto mt-12 md:mt-0 text-black">
              <h1 className="text-2xl md:text-4xl font-bold mb-4 text-center md:text-left">
                Design Thinking
              </h1>
              <p className="text-base md:text-lg text-gray p-4 text-justify">
                Design Thinking is a problem-solving approach that prioritizes
                empathy, creativity, and collaboration to generate innovative
                solutions. It involves understanding user needs, brainstorming
                ideas, prototyping, and iterating based on feedback. At [Your
                Company Name], we embrace design thinking methodologies to
                tackle complex challenges and create meaningful experiences for
                our users. By fostering a culture of innovation and iteration,
                we deliver solutions that resonate with our target audience.
              </p>
            </div>
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, x: -40 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -40 }}
          transition={{
            type: "tween",
            duration: 3,
            ease: [0.25, 0.25, 0.25, 0.75],
          }}
          className="flex flex-col md:flex-row justify-between items-center gap-10 mt-10"
        >
          <div className="flex flex-col md:flex-row">
            <div className="w-full md:w-1/2 mx-auto mt-12 md:mt-0 text-black">
              <h1 className="text-2xl md:text-4xl font-bold mb-4 text-center md:text-left">
                User Research
              </h1>
              <p className="text-base md:text-lg text-gray p-4 text-justify">
                User Research involves gathering insights and feedback from
                users to inform the design process. This can include techniques
                such as interviews, surveys, and usability testing to understand
                user behaviors, preferences, and pain points. At [Your Company
                Name], we prioritize user research to gain deep insights into
                our target audience and their needs. By conducting thorough
                research, we ensure that our designs are informed by real user
                data, leading to more impactful solutions.
              </p>
            </div>
            <div className="w-full md:w-1/2 mx-auto">
              <div className="overflow-hidden transform hover:translate-y-4 transition-all duration-300 max-w-sm mx-auto flex">
                <div className="w-80 h-80 md:w-96 md:h-96 mx-auto rounded-full bg-gradient-to-r from-purple-500 to-pink-500 shadow-custom object-cover">
                  <img
                    src={ui1}
                    alt=""
                    className="h-full w-full rounded-full"
                    style={{ objectPosition: "50% 50%" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default UIUx;
