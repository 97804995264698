 
import React, { useState, useEffect } from 'react';
import img1 from "../assets/cartoon1.jpg";
import img2 from "../assets/web1.jpg";
import img3 from "../assets/web.jpg";
 

const Slider = () => {
  const images = [img1, img2, img3];
  const [currentIndex, setCurrentIndex] = useState(0);
  const imageDisplayTime = 5;  

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, imageDisplayTime * 1000);  
    return () => clearInterval(interval);
  }, [images.length, imageDisplayTime]);

  return (
    <div className='slider-container max-w-screen-2xl mx-auto mt-5'>
      <div className='slider-images h-[80%]'>
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Slide ${index + 1}`}
            className={`slider-image  ${
              index === currentIndex ? 'active' : ''
            }`}
            style={{
              animationDuration: `${images.length * imageDisplayTime}s`,  
              animationDelay: `${index * imageDisplayTime}s`, 
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default Slider;
