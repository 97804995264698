import React, { useState } from "react";
import knackbay from "../assets/Knackbay (1).png";
import { FaInstagramSquare, FaLinkedin } from "react-icons/fa";
import { Link } from "react-router-dom";
import { MdCall, MdLocationOn } from "react-icons/md";
import { IoMdClose, IoMdMail } from "react-icons/io";
import logo from "../assets/knackbay_logo.png";

const Footer = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };
  const handleOpenContact = () => {
    setIsDialogOpen(true);
  };
  return (
    <div
      className="bg-blue md:px-14 p-4 max-w-screen-2xl mx-auto text-white flex flex-col items-start md:items-center"
      id="Contact"
    >
      <div className="mt-9 flex flex-col md:flex-row md:justify-center gap-10 pl-[30px] sm:pl-0">
        <div className="md:w-2/6 space-y-8 flex justify-center">
          <a
            href="/"
            className="text-2xl font font-semibold flex items-center space-x-3 text-royal-blue"
          >
            <img src={knackbay} alt="" className="w-80 h-auto mx-auto mt-3" />
          </a>
        </div>

        <div className="md:flex md:items-start md:w-4/6 md:ml-10 mt-6 md:mt-10 p-10 flex justify-center">
          <ul className="flex flex-col md:flex-row md:space-x-8 text-center">
            <li>
              <a href="/" className="block hover:text-gray">
                Home
              </a>
            </li>
            <li>
              <a href="/" className="block hover:text-gray">
                About Us
              </a>
            </li>
            <li>
              <a href="/" className="block hover:text-gray">
                Services
              </a>
            </li>
            <li>
              <h1 className="block hover:text-gray" onClick={handleOpenContact}>
                Contact Us
              </h1>
            </li>
            <li>
              <h1 className="font-quicksand font-bold mb-2">Follow Us</h1>
              <ul className="flex flex-row space-x-2 pr-[4px]">
                <li>
                  <Link to="https://www.linkedin.com/company/knackbay/mycompany/">
                    <FaLinkedin size={30} />
                  </Link>
                </li>
                <li>
                  <Link to="https://www.instagram.com/knackbay_/">
                    <FaInstagramSquare size={30} />
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>

      {/* Dialog Section */}
      <div
        className={`fixed inset-0 w-full h-full flex items-center justify-center z-50 ${
          isDialogOpen ? "block" : "hidden"
        } pr-[30px] sm:pr-0`}
      >
        <div className="bg-lightgray mt-24 p-4 w-full md:w-[60%] rounded-md shadow-md relative z-10">
          <div className="bg-white border border-primary w-full md:w-[95%] rounded-md ml-4 text-black h-auto max-h-[80vh]">
            <div className="flex flex-row items-center justify-between">
              <img src={logo} alt="" className="w-20" />
              <h3 className="text-primary font-bold mt-5">Contact Us</h3>
              <IoMdClose
                className="text-black cursor-pointer mt-5 mr-5"
                onClick={handleCloseDialog}
              />
            </div>

            <div className="p-6 flex flex-col md:flex-row md:justify-between">
              {/* Email and Contact Number */}
              <div className="flex flex-col space-y-4">
                <div className="flex items-center">
                  <span className="bg-blue w-13 h-13 flex items-center justify-center rounded-full p-2">
                    <IoMdMail className="text-white" size={28} />
                  </span>
                  <div className="ml-4">
                    <h4 className="font-bold">Email:</h4>
                    <h4 className="text-para">knackbay@gmail.com</h4>
                  </div>
                </div>

                <div className="flex items-center">
                  <span className="bg-blue w-13 h-13 flex items-center justify-center rounded-full p-2">
                    <MdCall className="text-white" size={28} />
                  </span>
                  <div className="ml-4">
                    <h4 className="font-bold">Contact Number:</h4>
                    <h4 className="text-para">+91 9025243272</h4>
                  </div>
                </div>
              </div>

              {/* Address Section */}
              <div className="flex items-center md:ml-10 mt-6 md:mt-0">
                <span className="bg-blue w-13 h-13 flex items-center justify-center rounded-full p-2">
                  <MdLocationOn className="text-white" size={28} />
                </span>
                <div className="ml-4">
                  <h4 className="font-bold">Address:</h4>
                  <ul className="mt-2 space-y-1">
                    <li className="text-sm md:text-base whitespace-normal md:whitespace-nowrap">
                      620/6, 1st Floor, Hari Tyre Building,
                      <h3>Katpadi, Vellore, Tamil Nadu</h3>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {/* Follow Us Section */}
            <div className="mx-auto py-6">
              <h4 className="text-center text-lg font-bold">Follow Us</h4>
              <div className="flex justify-center items-center text-blue space-x-4 mt-2">
                <Link to="https://www.linkedin.com/company/knackbay/mycompany/">
                  <FaLinkedin size={30} />
                </Link>
                <Link to="https://www.instagram.com/knackbay_/">
                  <FaInstagramSquare size={30} />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Footer Section */}
      <div className="flex justify-center mt-4 items-center pl-[30px] sm:pl-0">
        <p className="text-center">
          @ Knack Bay 2021 — 2024. All rights reserved.
        </p>
      </div>
    </div>
  );
};

export default Footer;
