import React from "react";
import web from "../../assets/web2.jpg";
import web1 from "../../assets/web11.jpg";
import web2 from "../../assets/web12.jpg";
import web3 from "../../assets/web13.jpg";
import web4 from "../../assets/web14.jpg";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const WebDevelop = () => {
  return (
    <div className="bg-gray-100">
      <div
        className="md:px-12 p-4 max-w-screen-2xl mx-auto mt-12 md:mt-28 bg-cover bg-center relative h-96"
        style={{ backgroundImage: `url(${web})` }}
      >
        <div className="bg-black bg-opacity-60 absolute inset-0"></div>
        <div className="relative z-10 text-white flex flex-col md:flex-row justify-center items-center gap-10 md:h-full">
          <div className="md:w-[60%]"></div>
          <div className="text-center md:text-left">
            <div className="text-4xl md:text-5xl font-bold mb-4">
              Welcome to Our Web Development Services
            </div>
            <Link to="/" className="hover:text-gray-300 cursor-pointer">
              <button className="bg-pink text-white px-4 py-2 rounded-full">
                Contact Us
              </button>
            </Link>
          </div>
        </div>
      </div>

      <div className="max-w-screen-2xl mx-auto w-5/6">
        <motion.div
          initial={{ opacity: 0, x: 40 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: 40 }}
          transition={{
            type: "tween",
            duration: 3,
            ease: [0.25, 0.25, 0.25, 0.75],
          }}
          className="flex flex-col md:flex-row-reverse justify-between items-center gap-10"
        >
          <div className="flex flex-col md:flex-row">
            <div className="w-full md:w-1/2 mx-auto">
              <div className="overflow-hidden transform hover:translate-y-4 transition-all duration-300 max-w-sm mx-auto">
                <div className="w-full h-96 md:h-96 mx-auto rounded-full bg-gradient-to-r from-purple-500 to-pink-500 shadow-custom object-cover">
                  <img
                    src={web1}
                    alt=""
                    className="h-full w-full rounded-full"
                    style={{ objectPosition: "50% 50%" }}
                  />
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/2 mx-auto mt-6 md:mt-0 text-black">
              <h1 className="text-3xl md:text-4xl font-bold mb-4">
                Creative Excellence in Website Designing
              </h1>
              <p className="text-base md:text-lg text-gray-700 p-4 text-justify">
                Our dedicated team at ResilienceSoft is committed to pushing the
                boundaries of website design, ensuring that your online presence
                is both visually stunning and highly functional. We understand
                that a well-crafted website is essential for making a lasting
                impression on your audience and effectively conveying your brand
                message.
              </p>
            </div>
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, x: -40 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -40 }}
          transition={{
            type: "tween",
            duration: 3,
            ease: [0.25, 0.25, 0.25, 0.75],
          }}
          className="flex flex-col md:flex-row-reverse justify-between items-center gap-10"
        >
          <div className="flex flex-col md:flex-row">
            <div className="w-full md:w-1/2 mx-auto mt-6 md:mt-0 text-black">
              <h1 className="text-3xl md:text-4xl font-bold mb-4">
                Elevating Web Application Development Artistry to Extraordinary Domains
              </h1>
              <p className="text-base md:text-lg text-gray-700 p-4 text-justify">
                Our team doesn't merely engage in web application development;
                we are architects of transformation, translating abstract
                visions into tangible, interactive realities. From the very
                beginning of ideas to the final high point of deployment, we
                infuse vitality into your concepts, ensuring a flawlessly
                seamless alignment with your constant business objectives.
              </p>
            </div>
            <div className="w-full md:w-1/2 mx-auto">
              <div className="overflow-hidden transform hover:translate-y-4 transition-all duration-300 max-w-sm mx-auto">
                <div className="w-full h-96 md:h-96 mx-auto rounded-full bg-gradient-to-r from-purple-500 to-pink-500 shadow-custom object-cover">
                  <img
                    src={web2}
                    alt=""
                    className="h-full w-full rounded-full"
                    style={{ objectPosition: "50% 50%" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, x: 40 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: 40 }}
          transition={{
            type: "tween",
            duration: 3,
            ease: [0.25, 0.25, 0.25, 0.75],
          }}
          className="flex flex-col md:flex-row-reverse justify-between items-center gap-10"
        >
          <div className="flex flex-col md:flex-row">
            <div className="w-full md:w-1/2 mx-auto">
              <div className="overflow-hidden transform hover:translate-y-4 transition-all duration-300 max-w-sm mx-auto">
                <div className="w-full h-96 md:h-96 mx-auto rounded-full bg-gradient-to-r from-purple-500 to-pink-500 shadow-custom object-cover">
                  <img
                    src={web3}
                    alt=""
                    className="h-full w-full rounded-full"
                    style={{ objectPosition: "50% 50%" }}
                  />
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/2 mx-auto mt-6 md:mt-0 text-black">
              <h1 className="text-3xl md:text-4xl font-bold mb-4">
                Elevating User-Centric Artistry in Web Application Development
              </h1>
              <p className="text-base md:text-lg text-gray-700 p-4 text-justify">
                The peak of significance is the user experience. In every phase
                of web application development, we design experiences that
                resonate profoundly. Our web applications are canvases enhanced
                with user-centric design, housing automatic interfaces, seamless
                navigation, and interactions that transcend mere transactions to
                become transformative engagements. Through each tap, swipe, and
                click, we sow the seeds of engagement, nurturing abundant
                satisfaction.
              </p>
            </div>
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, x: -40 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -40 }}
          transition={{
            type: "tween",
            duration: 3,
            ease: [0.25, 0.25, 0.25, 0.75],
          }}
          className="flex flex-col md:flex-row-reverse justify-between items-center gap-10"
        >
          <div className="flex flex-col md:flex-row">
            <div className="w-full md:w-1/2 mx-auto mt-6 md:mt-0 text-black">
              <h1 className="text-3xl md:text-4xl font-bold mb-4">
                Fine-Tuning the Symphony of Web Application Performance
              </h1>
              <p className="text-base md:text-lg text-gray-700 p-4 text-justify">
                Velocity and efficiency are the keystones of our craftsmanship.
                Our web application developers, similar to experts conducting a
                symphony, meticulously fine-tune the variations of performance.
                From rapid loading times that captivate attention to the
                harmonious responsiveness of interfaces, every aspect resonates
                in harmonious unity. The result is a symphony of interaction,
                creating a browsing experience that's not only seamless but
                truly delightful.
              </p>
            </div>
            <div className="w-full md:w-1/2 mx-auto">
              <div className="overflow-hidden transform hover:translate-y-4 transition-all duration-300 max-w-sm mx-auto">
                <div className="w-full h-96 md:h-96 mx-auto rounded-full bg-gradient-to-r from-purple-500 to-pink-500 shadow-custom object-cover">
                  <img
                    src={web4}
                    alt=""
                    className="h-full w-full rounded-full"
                    style={{ objectPosition: "50% 50%" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default WebDevelop;
